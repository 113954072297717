.navbar.fixed-top-mainmenu {
    height: 95px;
}

.fixed-top-mainmenu {
    z-index: 5;
}

.fixed-top-submenu {
    z-index: 4;
}

.navbar {
    align-items: center;
    display: flex;
}

.navbar-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;



    width: 100%;
}

.fixed-top {
    top: 0;
    position: fixed;
    right: 0;
    left: 0
}

.navbar-brand {
    display: inline-block;
    /*padding-top: 0.32rem;*/
    /*padding-bottom: 0.32rem;*/
    height: 100%;
    margin-right: 1rem;
    font-size: 1.125rem;
    line-height: inherit;
    white-space: nowrap;
    cursor: pointer;
}

.nav-item{
    color: black;
}

.nav-link{
    font-weight: 500;
    font-size: 1.1rem;
    padding: 0.5rem 0.8rem !important;
}

/*.nav-item .active .nav-link:hover{*/
/*    background-color: var(--secondary-color) !important;*/
/*    !*background-color: red !important;*!*/
/*}*/

.nav-item {
    padding: 0.75rem 0.5rem;
    border-radius: 1rem;
    cursor: pointer;
    color: #495057
}

.active {
    background-color: var(--navbar-active-color) !important;
    color: #14b8a6;
}

.nav-item:hover{
    background-color: var(--navbar-active-color)  !important;
}

/*.nav-link:hover span{*/
/*    color: hsla(0,0%,100%,.75) !important;*/
/*}*/

.nav-link:active,.nav-link:focus{
    outline: none !important;
    box-shadow: none !important;
}

.nav-link span{
    color: white !important;
}

.navbar.fixed-top .nav-item.active .nav-link {
    background-color: var(--secondary-color) !important;
    border-radius: 3px;
}

.navbar.fixed-top .nav-item.active .nav-link span {
    font-weight: 600 !important;
    color: var(--primary-color) !important;
}

.navbar-menu-bar{
    background: transparent !important;
    padding: 0 !important;
    /*display: flex;*/
    justify-content: end;
    flex-grow: 1;
    font-size: 1.25rem;
    cursor: pointer;
}

.navbar-menu-bar a:active, .navbar-menu-bar a:focus{
    outline: none !important;
    box-shadow: none !important;
}

.p-menubar-mobile-active .nav-link span{
    color: var(--primary-color) !important;
}

.fixed-top-mainmenu .p-menubar-button{
    color: white;
}

.fixed-top-mainmenu .p-menubar-button:hover{
    color: rgba(255,255,255, 0.8);
}

.display-none {
    display: none;
}
