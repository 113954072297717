@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap");

@font-face {
  font-family: "Pangolin";   /*Can be any text*/
  src: local("Pangolin-Regular"),
  url("./assets/fonts/Pangolin/Pangolin-Regular.ttf") format("truetype");
}

* {
  font-family: "Pangolin", sans-serif;
  --lara-toggle-button-toggle-button-text-color: #495057;
}

#root {
  height: 100%;
}

html {
  /*font-size: 16px;*/
  height: 100%;
}

body {
  --navbar-active-color: #f3f4f6;
  line-height: 1.6;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ededed;
  /* This is the height of the topbar */
  height: calc(100% - 95px);
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

main {
  margin-top: 95px;
  /*padding-top: 3.5rem;*/
  /*margin-bottom: 2rem;*/
  /* 9.5rem is the height of the footer , 2rem are for the margin-bottom of the body*/
  min-height: calc(100% - 9.5rem - 2rem);
}

a {
  color: var(--primary-color);
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

footer{
  width: 100%;
  position: relative;
  z-index: 1000;
  /* margin: 0;
  position: relative;
  bottom: 0; */
}

.component-container {
  overflow: auto !important;
  box-shadow: 0 2px 4px -1px rgba(0,0,0,.2),0 4px 5px 0 rgba(0,0,0,.14),0 1px 10px 0 rgba(0,0,0,.12);
  border-radius: 20px;
  background-color: white;
  margin: 2rem;
  width: 100%
}

h1 {
  font-weight: normal;
  margin: 0;
  font-size: 1.5rem;
}

h2 {
  font-size: 1.25rem;
  font-weight: normal;
  margin: 0;
}

.login-wrapper {
  background-image: url( './assets/login_bg_3.jpg' );
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (max-width: 480px) {
  .component-container {
    margin: 0.25rem;
  }
}



