.container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-right: 5rem;
    margin-left: 5rem;
    /*width: 100%*/

}

/*@media only screen and (max-width: 576px) {*/
/*    .container {*/
/*        max-width: 540px;*/
/*        margin-right: 1rem;*/
/*        margin-left: 1rem;*/
/*    }*/
/*}*/

/*@media only screen and (min-width: 768px) {*/
/*    .container {*/
/*        max-width: 95%;*/

/*    }*/


/*}*/

/*@media only screen and (min-width: 992px) {*/
/*    .container {*/
/*        max-width: 960px;*/

/*    }*/
/*}*/

/*@media only screen and (min-width: 1366px){*/
/*    .container {*/
/*        max-width: 100%;*/

/*    }*/
/*}*/
