#nav-icon3 {
    width: 40px;
    height: 50px;
    position: relative;
    margin-right: 2rem;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}

#nav-icon3 span {
    display: block;
    position: absolute;
    height: 6px;
    width: 100%;
    background: var(--primary-color);
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}


#nav-icon3 span:nth-child(1) {
    top: 9px;
}

#nav-icon3 span:nth-child(2),#nav-icon3 span:nth-child(3) {
    top: 18px;
}

#nav-icon3 span:nth-child(4) {
    top: 27px;
}

#nav-icon3.open span:nth-child(1) {
    top: 18px;
    width: 0%;
    left: 50%;
}

#nav-icon3.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
}

/*Mobile menu*/
.mobile-menu {
    flex-direction: column;
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: white;
    justify-content: center;
    transition: 1000ms ease all;
    list-style: none;
    top: calc(95px - 1rem);
    padding: 0;
    z-index: 4
}

.mobile-menu-li {
    font-size: 24px;
    /*color: #fff;*/
    text-align: center;
    padding: 20px 40px;
    cursor: pointer;
    text-transform: uppercase;
    transition: 500ms ease all;
}

.mobile-menu-li:hover {
    color: rgba(0, 242, 255, 0.9);
}

.slide-in {
    animation: slide-in 1000ms ease;
    display: inherit;
}

.slide-out {
    animation: slide-out 1000ms ease;
}

@keyframes slide-in {
    0% {
        transform: translateY(-100vh);
    }
    100% {
        transform: translateY(0);
    }
}
@keyframes slide-out {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-100vh);
    }
}
