.navbar.fixed-top-submenu {
    z-index: 1;
    height: 60px;
    margin-top: 95px;
    font-weight: 600;
    font-size: .9rem;
}

.navbar-submenu-bar {
    display: flex;
    padding: 0;
    font-weight: 600;
    font-size: 0.9rem;
    justify-content: end;
    flex-grow: 1;
}

.navbar-submenu-bar.p-menubar {
    background: transparent;
}

.navbar-submenu-bar ul li a span {
    color: var(--primary-color) !important;
    font-weight: 600 !important;
    font-size: 0.9rem !important;
}

.navbar-submenu-bar ul li a:active, .navbar-submenu-bar ul li a:focus {
    outline: none !important;
    box-shadow: none !important;
}

.navbar-submenu-bar ul li a:hover span {
    color: rgba(0, 0, 0, .7) !important;
}
